import {type ProductVariant} from "@prisma/client";
import {useFormAction, useNavigation, useRouteLoaderData} from '@remix-run/react'
import { clsx, type ClassValue } from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSpinDelay } from 'spin-delay'
import { extendTailwindMerge } from 'tailwind-merge'
import  {type loader as rootLoader} from "#app/root.tsx";
import { extendedTheme } from './extended-theme.ts'

export function getUserImgSrc(imageId?: string | null) {
	return imageId ? `/resources/user-images/${imageId}` : '/img/user.png'
}

export function getProductImgSrc(imageId: string) {
	return `/resources/product-images/${imageId}`
}

export function getBannerImgSrc(imageId: string) {
	return `/resources/banner-images/${imageId}`
}

export function getTeamImgSrc(imageId: string) {
	return `/resources/team-images/${imageId}`
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

export function generateSlug(text: string) {
	// generate a slug from a string and append a random number to it
	return `${slugify(text)}-${makeid(5)}`
}

export function makeid(length: number) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}

function slugify(str: string) {
	return String(str)
		.normalize('NFKD') // split accented characters into their base characters and diacritical marks
		.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
		.trim() // trim leading or trailing whitespace
		.toLowerCase() // convert to lowercase
		.replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
		.replace(/\s+/g, '-') // replace spaces with hyphens
		.replace(/-+/g, '-'); // remove consecutive hyphens
}

function formatColors() {
	const colors = []
	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === 'string') {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map(subKey =>
				subKey === 'DEFAULT' ? '' : subKey,
			)
			colors.push({ [key]: colorGroup })
		}
	}
	return colors
}

const customTwMerge = extendTailwindMerge<string, string>({
	extend: {
		theme: {
			colors: formatColors(),
			borderRadius: Object.keys(extendedTheme.borderRadius),
		},
		classGroups: {
			'font-size': [
				{
					text: Object.keys(extendedTheme.fontSize),
				},
			],
		},
	},
})

export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

export function formatBytes(
	bytes: number,
	opts: {
		decimals?: number
		sizeType?: "accurate" | "normal"
	} = {}
) {
	const { decimals = 0, sizeType = "normal" } = opts

	const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
	const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"]
	if (bytes === 0) return "0 Byte"
	const i = Math.floor(Math.log(bytes) / Math.log(1024))
	return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
		sizeType === "accurate"
			? (accurateSizes[i] ?? "Bytest")
			: (sizes[i] ?? "Bytes")
	}`
}

export function formatDateTime(dateTimeString: string, locale: string = 'de-CH') {
	const date = new Date(Date.parse(dateTimeString))
	return date.toLocaleString(locale, {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	})
}

export function formatDate(dateString: string, locale: string = 'de-CH') {
	dateString = dateString.split('T')[0]
	const parts = dateString.split('-')
	const hasDay = parts.length > 2

	return new Date(`${dateString}Z`).toLocaleDateString(locale, {
		day: hasDay ? 'numeric' : undefined,
		month: 'long',
		year: 'numeric',
		timeZone: 'UTC',
	})
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = request.headers.get('X-Forwarded-Proto') ?? 'http'
	return `${protocol}://${host}`
}

export function getReferrerRoute(request: Request) {
	// spelling errors and whatever makes this annoyingly inconsistent
	// in my own testing, `referer` returned the right value, but 🤷‍♂️
	const referrer =
		request.headers.get('referer') ??
		request.headers.get('referrer') ??
		request.referrer
	const domain = getDomainUrl(request)
	if (referrer?.startsWith(domain)) {
		return referrer.slice(domain.length)
	} else {
		return '/'
	}
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const merged = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			merged.set(key, value)
		}
	}
	return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const combined = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value)
		}
	}
	return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
	...responseInits: Array<ResponseInit | null | undefined>
) {
	let combined: ResponseInit = {}
	for (const responseInit of responseInits) {
		combined = {
			...responseInit,
			headers: combineHeaders(combined.headers, responseInit?.headers),
		}
	}
	return combined
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	)
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
	formAction,
	formMethod,
	delay = 400,
	minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
	Parameters<typeof useSpinDelay>[1] = {}) {
	const isPending = useIsPending({ formAction, formMethod })
	const delayedIsPending = useSpinDelay(isPending, {
		delay,
		minDuration,
	})
	return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
	...fns: Array<((...args: Args) => unknown) | undefined>
) {
	return (...args: Args) => fns.forEach(fn => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = useState(false)

	function getButtonProps(
		props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
	) {
		const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
			() => setDoubleCheck(false)

		const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
			doubleCheck
				? undefined
				: e => {
						e.preventDefault()
						setDoubleCheck(true)
					}

		const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] =
			e => {
				if (e.key === 'Escape') {
					setDoubleCheck(false)
				}
			}

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
			onKeyUp: callAll(onKeyUp, props?.onKeyUp),
		}
	}

	return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
	fn: Callback,
	delay: number,
) {
	let timer: ReturnType<typeof setTimeout> | null = null
	return (...args: Parameters<Callback>) => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

/**
 * Debounce a callback function
 */
export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = useRef(callback)
	useEffect(() => {
		callbackRef.current = callback
	})
	return useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay,
			),
		[delay],
	)
}

export async function downloadFile(url: string, retries: number = 0) {
	const MAX_RETRIES = 3
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`Failed to fetch image with status ${response.status}`)
		}
		const contentType = response.headers.get('content-type') ?? 'image/jpg'
		const blob = Buffer.from(await response.arrayBuffer())
		return { contentType, blob }
	} catch (e) {
		if (retries > MAX_RETRIES) throw e
		return downloadFile(url, retries + 1)
	}
}

export function getInitials(name: string) {
	const parts = name.split(' ')
	return parts
		.map(part => part[0])
		.slice(0, 2)
		.join('')
		.toUpperCase()
}

export function fullAddress(address: {
	street: string
	zip: string
	city: string
	country: string
}) {
	return `${address.street}, ${address.zip} ${address.city}, ${address.country}`
}

export const omit = (obj: Record<string, any>, keys: string[]) => {
	const result = { ...obj }
	keys.forEach(key => {
		delete result[key]
	})
	return result
}

export const addOrSubtractBusinessDays = (date: Date, days: number) => {
	const wks = Math.floor(days/5);
	let dys = ((days%5)+5)%5;
	let dy = date.getDay();

	if (dy === 6 && dys > -1) {
		if (dys === 0) {dys-=2; dy+=2;}
		dys++; dy -= 6;}
	if (dy === 0 && dys < 1) {
		if (dys === 0) {dys+=2; dy-=2;}
		dys--; dy += 6;}
	if (dy + dys > 5) dys += 2;
	if (dy + dys < 1) dys -= 2;
	date.setDate(date.getDate()+wks*7+dys);
	return date
}

export const isProductShippable = (product: {variants: Pick<ProductVariant, "reservedQuantity" | "stockQuantity">[]}) => {
	return product.variants.some(isProductVariantShippable)
}

export const isProductVariantShippable = (variant: Pick<ProductVariant, "reservedQuantity" | "stockQuantity">) => {
	return variant.stockQuantity - variant.reservedQuantity > 0
}

export const isOrderCancelable = (order: {status: string}) => {
	return ["SCHEDULED", "DRAFT"].includes(order.status)
}

export const isOrderEditable = (order: {status: string}) => {
	return ["SCHEDULED", "DRAFT"].includes(order.status)
}

export const normalizeDate = (date: Date | string, timezone = "Europe/Zurich") => {
	if (typeof date === "string") {
		date = new Date(date)
	}
	const normalizedDate = new Date(date.toLocaleString('en-US', {timeZone: timezone}))
	return new Date(normalizedDate.toISOString().split('T')[0])
}

export const normalizeDateTime = (date: Date | string, timezone = "Europe/Zurich") => {
	if (typeof date === "string") {
		date = new Date(date)
	}

	return new Date(date.toLocaleString('en-US', {timeZone: timezone}))
}

export const getMinDeliveryDate = () => {
	const today = normalizeDateTime(new Date())
	let minDeliveryDate = addOrSubtractBusinessDays(today, 3+1) // @todo we add an additional buffer of one day at the moment, maybe we can remove this later
	// our deadline is 14.00 for dispatching the same day, so we need to add one day if it is after 13.45
	const now = normalizeDateTime(new Date())
	if (now.getHours() > 13 || (now.getHours() === 13 && now.getMinutes() >= 45)) {
		// add one day
		minDeliveryDate = addOrSubtractBusinessDays(minDeliveryDate, 1)
	}
	return normalizeDate(minDeliveryDate)
}

export const isRealisticDeliveryDate = (date: Date) => {
	const minDeliveryDate = getMinDeliveryDate()
	const normalizedDate = normalizeDate(date)

	return minDeliveryDate <= normalizedDate && normalizedDate.getDay() !== 0 && normalizedDate.getDay() !== 6
}

export const isRealisticQuantity = (quantity: number, variant: Pick<ProductVariant, "stockQuantity" | "reservedQuantity">, multiplier: number = 1) => {
	return (quantity * multiplier) <= (variant.stockQuantity - variant.reservedQuantity)
}

export const getWebhookUrl = (webhook: {variant: string, id: string, secret: string}, host: string = '') => {
	return `${host}/api/webhooks/${webhook.variant.toLowerCase()}/${webhook.id}/${webhook.secret}`
}

export const getFullProductName = (productVariant: {name: string, product: {name: string}}) => {
	return productVariant.product.name !== productVariant.name ? `${productVariant.product.name} - ${productVariant.name}` : productVariant.name
}

export const isDemoMode = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const data = useRouteLoaderData<typeof rootLoader>('root')
	return data?.ENV?.DEMO_MODE === 'true'
}